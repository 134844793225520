import * as React from 'react';
import { NavLink, Link } from 'react-router-dom';
import {
  Pane,
  ListItem,
  UnorderedList,
  minorScale,
  SideSheet,
  Avatar,
  Heading,
  majorScale,
} from 'evergreen-ui';
import _ from 'lodash';
import { useReactiveVar } from '@apollo/client';
import AuthHeader from '../../auth-header/auth-header';
import { PRIMARY_NAV_ITEMS } from '../desktop-header/desktop-header';
import { ReactComponent as HamburgerIcon } from '../../../assets/icons/menu-hamburger.svg';
import ticketSirLogo from '../../../assets/images/ticketsir-logo.png';
import ticketSirLogoMobileIcon from '../../../assets/icons/ticketsir-mobile-logo.png';
import ticketSirLogoMobile from '../../../assets/images/ticketsir-logo-lg.png';
import Skeleton from 'react-loading-skeleton';
import { WithSubdomainLink } from '../../../hooks/use-subdomain';
import Notification from '../../notification-bell/notification-bell';
import useSubdomain from '../../../hooks/use-subdomain';
import { isAuthVar, authUserVar } from '../../../helpers/auth';
import Btn from '../../btn/btn';

const MobileHeader = ({ loadingLogo, subdomainLogoUrl }) => {
  const isAuth = useReactiveVar(isAuthVar);
  const authUser = useReactiveVar(authUserVar);
  const authUserFullName = `${authUser?.firstName} ${authUser?.lastName}`;
  const { subdomain } = useSubdomain();
  const logoUrlStart = _.startsWith(subdomainLogoUrl, 'http');

  const [isMobileModalOpen, setIsMobileModalOpen] = React.useState(false);

  const isPathDisabled = (paths) => paths.includes(window.location.pathname);

  return (
    <Pane
      is="header"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <NavLink to="/">
        {loadingLogo ? (
          <Skeleton width={50} />
        ) : (
          <>
            <Pane display="flex" alignItems="center">
              <img
                src={
                  logoUrlStart && subdomainLogoUrl
                    ? subdomainLogoUrl
                    : ticketSirLogoMobileIcon
                }
                alt="Ticketsir logo"
                style={{
                  verticalAlign: 'middle',
                  maxHeight: '60px',
                  paddingRight: !logoUrlStart && subdomain && '16px',
                  borderRight: !logoUrlStart && subdomain && '2px solid #fff',
                }}
              />
              {!logoUrlStart && subdomain && (
                <Heading
                  paddingLeft={majorScale(2)}
                  color="#fff"
                  fontSize="1.5em"
                >
                  {subdomainLogoUrl}
                </Heading>
              )}
            </Pane>
          </>
        )}
      </NavLink>

      <SideSheet
        width="80%"
        isShown={isMobileModalOpen}
        onCloseComplete={() => setIsMobileModalOpen(false)}
        preventBodyScrolling
        shouldCloseOnOverlayClick={true}
        containerProps={{
          marginLeft: 'auto',
          padding: '1rem',
          paddingBottom: '8rem',
          zIndex: 99999999,
        }}
      >
        <Pane display="flex" flexDirection="column" height="100%">
          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={minorScale(10)}
          >
            <NavLink to="/">
              {loadingLogo ? (
                <Skeleton width={50} />
              ) : (
                <img
                  src={subdomainLogoUrl || ticketSirLogoMobile}
                  alt="Ticketsir logo"
                  width="120rem"
                />
              )}
            </NavLink>
          </Pane>

          <UnorderedList display="flex" flexDirection="column">
            {PRIMARY_NAV_ITEMS.filter((item) => item.shown).map(
              (navItem, i) => {
                const {
                  title,
                  exact,
                  path,
                  domainOnly,
                  unauthenticatedRedirect,
                } = navItem;

                const targetPath =
                  !authUser?.id && unauthenticatedRedirect
                    ? unauthenticatedRedirect
                    : path;

                const LinkComponent = domainOnly ? WithSubdomainLink : NavLink;

                return (
                  <ListItem key={i} fontSize="inherit">
                    {!domainOnly && (
                      <LinkComponent
                        isNavLink={!!domainOnly}
                        exact={exact}
                        to={targetPath}
                        activeStyle={{ borderBottom: '1px solid #fff' }}
                        style={{
                          display: 'inline-block',
                          color: 'var(--text-default-color)',
                        }}
                      >
                        {title}
                      </LinkComponent>
                    )}
                    {domainOnly && (
                      <>
                        {authUser?.id && authUser?.hostId ? (
                          <Link to={path}>
                            <Btn
                              fontSize="16px"
                              look="pink-filled"
                              marginRight={40}
                              borderColor={'#C4C4C4'}
                              disableColor={'#C4C4C4'}
                              disabled={isPathDisabled([
                                '/how-to-create-event',
                                '/how-to-create-event-sign-in',
                                '/register-successfully',
                              ])}
                            >
                              {title}
                            </Btn>
                          </Link>
                        ) : (
                          <Link to={unauthenticatedRedirect}>
                            <Btn
                              look="pink-filled"
                              fontSize="16px"
                              marginRight={40}
                              borderColor={'#C4C4C4'}
                              disableColor={'#C4C4C4'}
                              disabled={isPathDisabled([
                                '/host/create-event',
                                '/how-to-create-event-sign-in',
                                '/register-successfully',
                              ])}
                            >
                              {title}
                            </Btn>
                          </Link>
                        )}
                      </>
                    )}
                  </ListItem>
                );
              }
            )}
          </UnorderedList>

          <Pane
            marginTop="100px"
            borderTop="1px solid var(--gray-lightest-color)"
            paddingY={minorScale(5)}
          >
            <AuthHeader />
          </Pane>
        </Pane>
      </SideSheet>

      <Pane display="flex" alignItems="center">
        {!subdomain?.length && (
          <Pane marginRight={majorScale(3)}>
            <Notification />{' '}
          </Pane>
        )}
        {isAuth && (
          <Link to="/account/profile" exact="true">
            <Pane
              display="flex"
              alignItems="center"
              color="#fff"
              cursor="pointer"
              marginRight={majorScale(1)}
            >
              <Avatar
                size={minorScale(6)}
                appearance="solid"
                color="blue"
                name={authUserFullName}
                fontSize="1em"
                className="mr-sm-2"
              />
            </Pane>
          </Link>
        )}

        <HamburgerIcon
          cursor="pointer"
          onClick={() => setIsMobileModalOpen(true)}
        />
      </Pane>
    </Pane>
  );
};

export default MobileHeader;
