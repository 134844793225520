import axios from 'axios';
import { toaster } from 'evergreen-ui';
import clientStorage from '../../libs/client-storage';
import { TS_AUTH_USER_REST_TOKEN } from '../../constants';
import { handleLogout } from '../auth';
import { getCookie, encryptData } from '../functions';

const _fbc = getCookie('_fbc');
const _fbp = getCookie('_fbp');

const Instance = axios.create({
  baseURL: process.env.REACT_APP_COKOBAR_ENDPOINT,
  timeout: 200000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    key: encryptData(
      'jq0735Xnd+gkQuLtnescmAK9wt4LjoEO3IAUih1dkdM+y7Y/yITDMR+8s0SuOkcg'
    ),
    ...(_fbc ? { _fbc } : {}),
    ...(_fbp ? { _fbp } : {}),
  },
});

function setToken(token) {
  clientStorage.setItem(TS_AUTH_USER_REST_TOKEN, token);
  Instance.defaults.headers.common.Authorization = `Bearer ${token}`;
}

const controller = new AbortController();
const signal = controller.signal;

// Request Interceptor
Instance.interceptors.request.use(
  async (config) => {
    const token = clientStorage.getItem(TS_AUTH_USER_REST_TOKEN);
    config.headers.Authorization = `Bearer ${token}`;

    // Adding the abort controller signal to the request
    config.signal = signal;

    // console.warn(`[Request] ${config.url}: `, JSON.stringify(config.data));
    return config;
  },
  (error) => {
    if (process.env.NODE_ENV === 'development') {
      console.error(`[Request Error] in ${error.request?._url}: `, error);
    }
    return Promise.reject(error.message);
  }
);

// Response Interceptor
Instance.interceptors.response.use(
  (response) => {
    if (process.env.NODE_ENV === 'development') {
      console.warn(
        `[Response] of ${response.config.url}: `,
        JSON.stringify(response.data)
      );
    }
    if (response.data?.responseType === 'Error') {
      return Promise.reject(response.data.message);
    }
    return response.data;
  },
  (error) => {
    if (process.env.NODE_ENV === 'development') {
      console.error(`[Response Error] in ${error.config?.url}: `, error);
    }
    const data = error.response?.data;
    const statusCode = error?.response?.status;
    let errorMessage = '';

    if (statusCode == 401) {
      handleLogout();
      toaster.notify('Your session has expired. Please login again.', {
        id: 'session_expired',
      });
    }

    if (data) {
      if (process.env.NODE_ENV === 'development') {
        console.error(
          `[Response Error.Response.Data] in ${error.config?.url}: `,
          JSON.stringify(data)
        );
      }
      if (data.data && Array.isArray(data.data) && data.data.length > 0) {
        const errorMessageArray =
          data.data.map((currentValue) => currentValue.message) || [];
        errorMessage = errorMessageArray.join('\n');
      } else if (data && data.errors && Object.keys(data.errors).length > 0) {
        errorMessage = 'Something went wrong. Please try again.';
      } else {
        const errorMag = data.error.Message ? data.error.Message : data.message;
        errorMessage = errorMag;
      }
    } else {
      errorMessage = error.message;
    }

    return Promise.reject(errorMessage);
  }
);

export { Instance, setToken };
