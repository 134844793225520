import React from 'react';
import { Pane } from 'evergreen-ui';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ScrollToTop from '../helpers/scroll-to-top';
import RenderAppSubContents from './app-subcontent/app-subcontent';
import LoadingStripe from '../components/loading-state-spinner/laoding-stripe';

// Pages dynamic imports
// routes
const UserProtectedRoute = React.lazy(() =>
  import('../routes/user-protected-route/user-protected-route')
);
const HostProtectedRoute = React.lazy(() =>
  import('../routes/host-protected-route/host-protected-route')
);

// static pages
const Home = React.lazy(() => import('../pages/home/home'));
const AboutUs = React.lazy(() => import('../pages/about-us/about-us'));
const HowItWorks = React.lazy(() =>
  import('../pages/how-it-works/how-it-works')
);
const Faqs = React.lazy(() => import('../pages/faqs/faqs'));
const ContactUs = React.lazy(() => import('../pages/contact-us/contact-us'));
const PrivacyPolicy = React.lazy(() =>
  import('../pages/privacy-policy/privacy-policy')
);
const HelpCenter = React.lazy(() => import('../pages/help-center/help-center'));

const Termandcondition = React.lazy(() =>
  import('../pages/Termandcondition/Termandcondition')
);

const Termandconditionforapp = React.lazy(() =>
  import('../pages/Termandconditionforapp/Termandconditionforapp')
);

const MobileAppRedirect = React.lazy(() =>
  import('../pages/mobile-app-redirect/mobile-app-redirect')
);

const HowToCreateEvent = React.lazy(() =>
  import('../pages/how-to-create-event/how-to-create-event')
);

const HowToCreateEventSignInForm = React.lazy(() =>
  import('../pages/how-to-create-event/create-event/create-event')
);

const RegisterSuccessfully = React.lazy(() =>
  import(
    '../pages/how-to-create-event/create-event/sign-in/login-register-page/register-successfully/register-successfully'
  )
);

const HostingEventFaq = React.lazy(() =>
  import('../pages/help-center/frequently-ask-question/hosting-an-event-faq')
);

const AttendingEventFaq = React.lazy(() =>
  import('../pages/help-center/frequently-ask-question/attending-an-event-faq')
);

// events
const SearchEvents = React.lazy(() =>
  import('../pages/search-events/search-events')
);
const EventDetails = React.lazy(() =>
  import('../pages/event-details/event-details')
);
const BuyTickets = React.lazy(() => import('../pages/buy-tickets/buy-tickets'));
const PreSaleTickets = React.lazy(() =>
  import('../pages/presale-tickets/presale-tickets')
);
const RegisterInterest = React.lazy(() =>
  import('../pages/register-interest/register-interest')
);

const BuyTicketsTimeout = React.lazy(() =>
  import(
    '../features/buy-tickets/buy-tickets-steps/booking-timeout/booking-timeout'
  )
);

const EventRedirect = React.lazy(() =>
  import('../pages/event-redirect/event-redirect')
);

// auth
const SignIn = React.lazy(() => import('../pages/signin/signin'));
const Register = React.lazy(() => import('../pages/register/register'));
const ForgotPassword = React.lazy(() =>
  import('../pages/forgot-password/forgot-password')
);
const EmailVerification = React.lazy(() =>
  import('../pages/email-verification/email-verification')
);
const ResetPassword = React.lazy(() =>
  import('../pages/reset-password/password-reset')
);

const ResendActivation = React.lazy(() =>
  import('../pages/resend-activation/resend-activation')
);

const AccountActivate = React.lazy(() =>
  import('../pages/account-activate/account-activate')
);

// user
const Account = React.lazy(() => import('../pages/account/account'));
const ContactHost = React.lazy(() =>
  import('../pages/contact-host/contact-host')
);
const HelpTicketDetails = React.lazy(() =>
  import(
    '../pages/account/help-tickets/features/help-ticket-details/help-ticket-details'
  )
);

const TransferTickets = React.lazy(() =>
  import('../pages/transfer-tickets/transfer-tickets')
);

const Wishlist = React.lazy(() => import('../pages/wishlist/wishlist'));
const NotificationPage = React.lazy(() =>
  import('../pages/notification-page/notification-page')
);
const FollowHostPage = React.lazy(() =>
  import('../pages/follow-host-page/follow-host-page')
);
const MyMessages = React.lazy(() => import('../pages/my-messages/my-messages'));

const BuyTicketsConfirmation = React.lazy(() =>
  import(
    '../features/buy-tickets/buy-tickets-steps/booking-payment-confirmation/booking-payment-confirmation'
  )
);

const BuyTicketsConfirmationFirePay = React.lazy(() =>
  import(
    '../features/buy-tickets/buy-tickets-steps/booking-payment-confirmation-firepay/booking-payment-confirmation'
  )
);

const BuyTicketsConfirmationExternal = React.lazy(() =>
  import(
    '../features/buy-tickets/buy-tickets-steps/booking-payment-confirmation-external/booking-payment-confirmation'
  )
);

const FirePayReturnPage = React.lazy(() =>
  import(
    '../features/buy-tickets/buy-tickets-steps/booking-payment-confirmation-firepay/components/fire-pay-return-page/fire-pay-return-page'
  )
);

// host
const CreateHostAccount = React.lazy(() =>
  import('../pages/host/create-host-account/create-host-account')
);

const Host = React.lazy(() => import('../pages/host/host'));
const NotFound = React.lazy(() => import('../pages/not-found/not-found'));

const SalesTracker = React.lazy(() =>
  import('../pages/sales-tracker/single-event/single-event')
);

const MulipleDateEventsSalesTracker = React.lazy(() =>
  import('../pages/sales-tracker/multiple-events/multiple-events')
);
const SalesEventsProducts = React.lazy(() =>
  import('../pages/sales-tracker/sales-events-products/sales-events-products')
);

const ExternalRegisterInterest = React.lazy(() =>
  import('../pages/external-register-interest/external-register-interest')
);

function App() {
  return (
    <Router>
      <React.Suspense
        fallback={
          <Pane
            width="100vw"
            height="100vh"
            display="flex"
            justifyContent="center"
          >
            <LoadingStripe />
          </Pane>
        }
      >
        <RenderAppSubContents />
        <ScrollToTop />
        <Route
          render={({ location }) => {
            return (
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  classNames="fade"
                  timeout={300}
                >
                  <Switch location={location}>
                    <Route exact path="/">
                      <Home />
                    </Route>
                    <Route exact path="/frontend-web-app">
                      {() => {
                        const responseData = { status: 'OK' };
                        return <>{JSON.stringify(responseData)}</>;
                      }}
                    </Route>
                    <Route exact path="/about-us">
                      <AboutUs />
                    </Route>
                    <Route exact path="/how-it-works">
                      <HowItWorks />
                    </Route>
                    <Route exact path="/faqs">
                      <Faqs />
                    </Route>
                    <Route exact path="/contact-us">
                      <ContactUs />
                    </Route>
                    <Route exact path="/privacy-policy">
                      <PrivacyPolicy />
                    </Route>
                    <Route exact={false} path="/help-center">
                      <HelpCenter />
                    </Route>

                    <Route exact={false} path="/term-and-condition">
                      <Termandcondition />
                    </Route>

                    <Route
                      exact={false}
                      path="/hosting-an-event/frequently-ask-question"
                    >
                      <HostingEventFaq />
                    </Route>

                    <Route
                      exact={false}
                      path="/attending-an-event/frequently-ask-question"
                    >
                      <AttendingEventFaq />
                    </Route>

                    <Route exact={false} path="/how-to-create-event">
                      <HowToCreateEvent />
                    </Route>

                    <Route exact={false} path="/how-to-create-event-sign-in">
                      <HowToCreateEventSignInForm />
                    </Route>

                    <Route exact={false} path="/register-successfully">
                      <RegisterSuccessfully />
                    </Route>

                    <Route exact={false} path="/term-and-condition-app">
                      <Termandconditionforapp />
                    </Route>

                    {/* Events */}
                    <Route exact path="/search-events">
                      <SearchEvents />
                    </Route>
                    <Route exact path="/events/:id/:evnetname">
                      <EventDetails />
                    </Route>
                    <Route exact path="/market/events/view/:eventrefnumber">
                      <EventRedirect />
                    </Route>
                    <Route exact path="/events/:id/:evnetname/buy">
                      <BuyTickets />
                    </Route>
                    <Route exact path="/events/:id/:evnetname/presale">
                      <PreSaleTickets />
                    </Route>
                    <Route exact path="/events/:id/:evnetname/presale/:code">
                      <PreSaleTickets />
                    </Route>
                    <Route exact path="/events/:id/buy/booking-timeout">
                      <BuyTicketsTimeout />
                    </Route>
                    <Route
                      exact
                      path="/events/:id/:evnetname/register-interest"
                    >
                      <RegisterInterest />
                    </Route>
                    <Route exact path="/events/:id/:evnetname/wait-list">
                      <ExternalRegisterInterest />
                    </Route>

                    {/* Events old route to redirect */}
                    <Route exact path="/events/:id">
                      <EventRedirect />
                    </Route>

                    <Route exact path="/events/:id/buy">
                      <EventRedirect />
                    </Route>
                    <Route exact path="/events/:id/presale">
                      <EventRedirect />
                    </Route>
                    <Route exact path="/events/:id/presale/:code">
                      <EventRedirect />
                    </Route>
                    <Route exact path="/events/:id/register-interest">
                      <EventRedirect />
                    </Route>

                    {/* Auth */}
                    <Route exact path="/signin">
                      <SignIn />
                    </Route>
                    <Route exact path="/register">
                      <Register />
                    </Route>
                    <Route exact path="/forgot-password">
                      <ForgotPassword />
                    </Route>
                    <Route exact path="/reset-password">
                      <ResetPassword />
                    </Route>
                    <Route exact path="/email-verification">
                      <EmailVerification />
                    </Route>
                    <Route exact path="/resend-activation">
                      <ResendActivation />
                    </Route>
                    <Route exact path="/account-activate">
                      <AccountActivate />
                    </Route>

                    {/*  Sales Tracker*/}
                    <Route
                      exact
                      path="/sales-tracker/event/:id/:evnetname/:eventdateid"
                    >
                      <SalesTracker />
                    </Route>

                    <Route exact path="/sales-tracker/events/:id/:evnetname">
                      <MulipleDateEventsSalesTracker />
                    </Route>

                    <Route
                      exact
                      path="/sales-tracker/products/:id/:evnetname/:eventdateid"
                    >
                      <SalesEventsProducts />
                    </Route>

                    <Route exact path="/mobile-app-redirect/:id">
                      <MobileAppRedirect />
                    </Route>

                    <Route exact path="/payment-confirmation-firepay/:id">
                      <BuyTicketsConfirmationFirePay />
                    </Route>

                    <Route
                      exact
                      path="/payment-confirmation-firepay/:id/return"
                    >
                      <FirePayReturnPage />
                    </Route>

                    <Route
                      exact
                      path="/payment-confirmation-external/:id/return"
                    >
                      <FirePayReturnPage />
                    </Route>

                    <Route exact path="/payment-confirmation-external/:id">
                      <BuyTicketsConfirmationExternal />
                    </Route>

                    <Route exact path="/payment-confirmation/:id">
                      <BuyTicketsConfirmation />
                    </Route>

                    {/* User */}
                    <UserProtectedRoute
                      path="/contact-host/:id"
                      component={ContactHost}
                    />
                    <UserProtectedRoute
                      path="/help-tickets-details/:id"
                      component={HelpTicketDetails}
                    />
                    <UserProtectedRoute
                      path="/transfer-ticket/:id"
                      component={TransferTickets}
                    />
                    <UserProtectedRoute
                      path="/my-messages"
                      component={MyMessages}
                    />
                    <UserProtectedRoute path="/account" component={Account} />
                    <UserProtectedRoute path="/wishlist" component={Wishlist} />

                    <UserProtectedRoute
                      path="/notfication"
                      component={NotificationPage}
                    />

                    <UserProtectedRoute
                      path="/follow-host"
                      component={FollowHostPage}
                    />

                    {/* Host */}
                    <UserProtectedRoute
                      exact
                      path="/host/create-account"
                      component={CreateHostAccount}
                    />

                    <HostProtectedRoute path="/host" component={Host} />
                    {/* Catch-all */}
                    <Route path="*">
                      <NotFound />
                    </Route>
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            );
          }}
        />
      </React.Suspense>
    </Router>
  );
}
export default App;
